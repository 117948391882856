export const memeVideos = [
  {
    id: 'meme1',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/Hawk%20Tuah%20Clip%20-%20Original%20(EDIT)%20(%20Hawk%20Tuhh%20).mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvSGF3ayBUdWFoIENsaXAgLSBPcmlnaW5hbCAoRURJVCkgKCBIYXdrIFR1aGggKS5tcDQiLCJpYXQiOjE3MzY4MTk3OTgsImV4cCI6MTc2ODM1NTc5OH0.fbaTLJLQyZ9jUGqtRxvhFHYzZLof-ohQ6jMGktd54MU',
    title: 'Hawk Tuah Limited Edition T-Shirt',
    price: 25.00,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/meme%20product%20images/Hawk%20tuah%20product%20image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvbWVtZSBwcm9kdWN0IGltYWdlcy9IYXdrIHR1YWggcHJvZHVjdCBpbWFnZS5wbmciLCJpYXQiOjE3MzY4OTAyNzQsImV4cCI6MTc2ODQyNjI3NH0.kMmuHYKU2L6Hj9CqggK9W5CiakpKJqO1ArOyxAs8yLI',
    celebrity: {
      name: 'Hailey Welch',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Haliey%20Welch.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvSGFsaWV5IFdlbGNoLmpwZyIsImlhdCI6MTczOTQ4NDkwOCwiZXhwIjoxNzcxMDIwOTA4fQ.uQ0bUi46sx9Jt0FhVEloG3m6tnq2KRDO8moGGRUa6Fs',
      verified: true
    }
  },
  {
    id: 'meme2',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/Homer%20disappears%20into%20bushes.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvSG9tZXIgZGlzYXBwZWFycyBpbnRvIGJ1c2hlcy5tcDQiLCJpYXQiOjE3MzY4MTk4MzIsImV4cCI6MTc2ODM1NTgzMn0.vRVQ-SbVDbKd8ztOuen_Q3BWJ4aKER9esxOU_1e4Jwo',
    title: 'Homer Bush Meme T-Shirt',
    price: 25.00,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/meme%20videos/meme%20product%20images/Homer%20Simpson%20product%20image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvbWVtZSB2aWRlb3MvbWVtZSBwcm9kdWN0IGltYWdlcy9Ib21lciBTaW1wc29uIHByb2R1Y3QgaW1hZ2UucG5nIiwiaWF0IjoxNzM2ODkwMjg3LCJleHAiOjE3Njg0MjYyODd9.WyX01ks40jcepBaT7RBvvi_CJESTYrEYT4Md4WJOl-M',
    celebrity: {
      name: 'Disney',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Disney.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvRGlzbmV5LmpmaWYiLCJpYXQiOjE3Mzk0ODQ5NjIsImV4cCI6MTc3MTAyMDk2Mn0.ade28tHgHo5l61HvkLMZI857AK3vEQFQYCvSrQoRMS4',
      verified: true
    }
  }
];