export const musicVideos = [
  {
    id: 'music1',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/music%20tab/ScreenRecording_01-16-2025%2020-10-00_1.mov?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9tdXNpYyB0YWIvU2NyZWVuUmVjb3JkaW5nXzAxLTE2LTIwMjUgMjAtMTAtMDBfMS5tb3YiLCJpYXQiOjE3MzcwODAyMzMsImV4cCI6MTc2ODYxNjIzM30.GHVO6fzCY0nh9SGzrR-_0MAkmRSX2GeR4Z7cwIVxtB8',
    title: "Jack Daniel's Label Tee",
    price: 29.99,
    productImage: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/music%20tab/Jack-Daniel-s-Men-s-Short-Sleeve-Label-Tee_415b9151-fb8a-431d-a43f-837d66c01646_1.0ad1269f2485e42e8d5fc8929667ea05.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9tdXNpYyB0YWIvSmFjay1EYW5pZWwtcy1NZW4tcy1TaG9ydC1TbGVldmUtTGFiZWwtVGVlXzQxNWI5MTUxLWZiOGEtNDMxZC1hNDNmLTgzN2Q2NmMwMTY0Nl8xLjBhZDEyNjlmMjQ4NWU0MmU4ZDVmYzg5Mjk2NjdlYTA1LndlYnAiLCJpYXQiOjE3MzcwODAyOTUsImV4cCI6MTc2ODYxNjI5NX0.fbJNvkno4xY7gMB-OQdc4TGXIDwDXWeHFsoseSrDcPM',
    celebrity: {
      name: 'Chris Stapleton',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Chris%20Stapleton.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvQ2hyaXMgU3RhcGxldG9uLmpwZyIsImlhdCI6MTczOTQ4NDg2OCwiZXhwIjoxNzcxMDIwODY4fQ.p9kELXKzGgsU-5zKilwIJRo4TqCVqwj8w1qYr5B4OxY',
      verified: true
    }
  }
];