import React, { useState, useEffect } from 'react';
import { BarChart3, TrendingUp, DollarSign, Film, Music, Trophy, Timer, ArrowUp, Users } from 'lucide-react';

interface Auction {
  id: string;
  title: string;
  category: 'Movies' | 'Music' | 'Sports';
  imageUrl: string;
  currentBid: number;
  timeLeft: number;
  totalBids: number;
  seller: {
    name: string;
    avatar: string;
    verified: boolean;
  };
  lastBidder: {
    name: string;
    avatar: string;
  };
}

export function MarketplacePage() {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [liveAuctions, setLiveAuctions] = useState<Auction[]>([
    {
      id: '1',
      title: "Inception Original Movie Poster - Signed by Cast",
      category: 'Movies',
      imageUrl: "https://images.unsplash.com/photo-1440404653325-ab127d49abc1",
      currentBid: 1250,
      timeLeft: 3600,
      totalBids: 23,
      seller: {
        name: "Christopher Nolan",
        avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",
        verified: true
      },
      lastBidder: {
        name: "Movie Collector",
        avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
      }
    },
    {
      id: '2',
      title: "Taylor Swift Signed Guitar - Eras Tour",
      category: 'Music',
      imageUrl: "https://images.unsplash.com/photo-1516924962500-2b4b3b99ea02",
      currentBid: 5000,
      timeLeft: 7200,
      totalBids: 45,
      seller: {
        name: "Taylor Swift",
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
        verified: true
      },
      lastBidder: {
        name: "Swiftie Fan",
        avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d"
      }
    },
    {
      id: '3',
      title: "Signed NBA Championship Game Ball",
      category: 'Sports',
      imageUrl: "https://images.unsplash.com/photo-1546519638-68e109498ffc",
      currentBid: 3200,
      timeLeft: 5400,
      totalBids: 32,
      seller: {
        name: "LeBron James",
        avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e",
        verified: true
      },
      lastBidder: {
        name: "Sports Enthusiast",
        avatar: "https://images.unsplash.com/photo-1463453091185-61582044d556"
      }
    }
  ]);

  // Simulate live updates
  useEffect(() => {
    const interval = setInterval(() => {
      setLiveAuctions(auctions => 
        auctions.map(auction => ({
          ...auction,
          timeLeft: auction.timeLeft > 0 ? auction.timeLeft - 1 : 0,
          currentBid: Math.random() > 0.8 ? auction.currentBid + Math.floor(Math.random() * 100) : auction.currentBid,
          totalBids: Math.random() > 0.8 ? auction.totalBids + 1 : auction.totalBids
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const categories = [
    { id: 'all', name: 'All Categories', icon: BarChart3 },
    { id: 'movies', name: 'Movies', icon: Film },
    { id: 'music', name: 'Music', icon: Music },
    { id: 'sports', name: 'Sports', icon: Trophy }
  ];

  const filteredAuctions = selectedCategory === 'all' 
    ? liveAuctions 
    : liveAuctions.filter(auction => 
        auction.category.toLowerCase() === selectedCategory
      );

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-3 bg-green-100 dark:bg-green-900/20 rounded-lg">
                <TrendingUp className="w-6 h-6 text-green-600 dark:text-green-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Total Sales</p>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white">$1.2M</h3>
              </div>
            </div>
            <div className="text-sm text-green-600">↑ 12% from last month</div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-3 bg-blue-100 dark:bg-blue-900/20 rounded-lg">
                <Users className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Active Bidders</p>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white">2,391</h3>
              </div>
            </div>
            <div className="text-sm text-blue-600">↑ 8% from last month</div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-3 bg-purple-100 dark:bg-purple-900/20 rounded-lg">
                <DollarSign className="w-6 h-6 text-purple-600 dark:text-purple-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Average Bid</p>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white">$142</h3>
              </div>
            </div>
            <div className="text-sm text-purple-600">↑ 3% from last month</div>
          </div>
        </div>

        {/* Category Navigation */}
        <div className="flex gap-4 mb-8 overflow-x-auto pb-2">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`flex items-center gap-2 px-6 py-3 rounded-xl transition-all ${
                selectedCategory === category.id
                  ? 'bg-[#F8CA7E] text-white shadow-lg'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <category.icon className="w-5 h-5" />
              <span className="whitespace-nowrap">{category.name}</span>
            </button>
          ))}
        </div>

        {/* Live Auctions */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredAuctions.map(auction => (
            <div key={auction.id} className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all">
              <div className="relative">
                <img 
                  src={auction.imageUrl} 
                  alt={auction.title}
                  className="w-full h-48 object-cover"
                />
                {/* Time Left Badge */}
                <div className="absolute top-4 right-4 bg-black/70 backdrop-blur-sm px-3 py-1 rounded-full flex items-center gap-2">
                  <Timer className="w-4 h-4 text-white" />
                  <span className="text-white text-sm font-medium">
                    {formatTime(auction.timeLeft)}
                  </span>
                </div>
                {/* Category Badge */}
                <div className="absolute top-4 left-4 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm px-3 py-1 rounded-full">
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {auction.category}
                  </span>
                </div>
              </div>

              <div className="p-4">
                <div className="flex items-start justify-between gap-4 mb-4">
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {auction.title}
                  </h3>
                  <div className="flex items-center gap-1 text-green-500">
                    <ArrowUp className="w-4 h-4" />
                    <span className="text-sm font-medium">{auction.totalBids}</span>
                  </div>
                </div>

                {/* Seller Info */}
                <div className="flex items-center gap-2 mb-4">
                  <img
                    src={auction.seller.avatar}
                    alt={auction.seller.name}
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {auction.seller.name}
                  </span>
                  {auction.seller.verified && (
                    <span className="text-[#F8CA7E]">✓</span>
                  )}
                </div>

                {/* Current Bid */}
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Current Bid</p>
                    <p className="text-xl font-bold text-gray-900 dark:text-white">
                      ${auction.currentBid.toLocaleString()}
                    </p>
                  </div>
                  <button className="px-4 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors">
                    Place Bid
                  </button>
                </div>

                {/* Last Bidder */}
                <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700 flex items-center gap-2">
                  <img
                    src={auction.lastBidder.avatar}
                    alt={auction.lastBidder.name}
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Last bid by {auction.lastBidder.name}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}