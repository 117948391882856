import React from 'react';
import { ShoppingBag, Heart, Share2, Star, Play } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Product {
  id: string;
  title: string;
  description: string;
  price: number;
  imageUrl: string;
  category: string;
  rating: number;
  specialLink?: string;
}

const products: Product[] = [
  {
    id: '1',
    title: 'Disney+ Premium Annual Subscription',
    description: 'Unlimited access to Disney+, Hulu, and ESPN+ for one year',
    price: 139.99,
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Disney%20plus.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L0Rpc25leSBwbHVzLmpmaWYiLCJpYXQiOjE3NDAxNzI2NzgsImV4cCI6MTc3MTcwODY3OH0.Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs_Hs',
    category: 'Streaming',
    rating: 4.8
  },
  {
    id: '2',
    title: 'Disney Parks VIP Pass',
    description: 'Skip-the-line access to all Disney theme park attractions',
    price: 299.99,
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Disney%20Parks.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L0Rpc25leSBQYXJrcy5qZmlmIiwiaWF0IjoxNzQwMTcyNzA0LCJleHAiOjE3NzE3MDg3MDR9.7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q7Q',
    category: 'Theme Parks',
    rating: 4.9
  },
  {
    id: '3',
    title: 'The Lion King Collection',
    description: 'Limited edition collectibles from the classic animated film',
    price: 149.99,
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/The%20lion%20King.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1RoZSBsaW9uIEtpbmcuamZpZiIsImlhdCI6MTczOTY3MjY2NywiZXhwIjoxNzcxMjA4NjY3fQ.t-CRkviLV4IAAQ4gMBkGxk8XXf49zhmeGdZjmpfhpWU',
    category: 'Collectibles',
    rating: 4.7
  },
  {
    id: '4',
    title: 'Moana Adventure Set',
    description: 'Complete collection of Moana merchandise and accessories',
    price: 89.99,
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Moana.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L01vYW5hLmpmaWYiLCJpYXQiOjE3Mzk2NzI2NDksImV4cCI6MTc3MTIwODY0OX0.XU4ajDbF0LdqgOgGyh8LZkv9aDwD3nMa9M2BraETrQw',
    category: 'Merchandise',
    rating: 4.6
  },
  {
    id: '5',
    title: 'Shrek Collectibles Collection',
    description: 'Exclusive collection of limited edition Shrek merchandise and collectibles',
    price: 199.99,
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Shrek.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1NocmVrLmpmaWYiLCJpYXQiOjE3Mzk2NzI2MzMsImV4cCI6MTc3MTIwODYzM30.qufNAYnVtvGDUbjCdW3iDLp7hKSLFrmx2psuwzmBQUw',
    category: 'Collectibles',
    rating: 4.9,
    specialLink: '/disney/shrek-collectibles'
  }
];

const categories = ['All', 'Streaming', 'Theme Parks', 'Collectibles', 'Merchandise'];

export function DisneyProfile() {
  const [activeCategory, setActiveCategory] = React.useState('All');
  const navigate = useNavigate();

  const filteredProducts = activeCategory === 'All' 
    ? products 
    : products.filter(product => product.category === activeCategory);

  const handleProductClick = (product: Product) => {
    if (product.specialLink) {
      navigate(product.specialLink);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Hero Section */}
      <div className="relative h-[500px] overflow-hidden">
        <img
          src="https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Disney%20cover%20photo.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L0Rpc25leSBjb3ZlciBwaG90by5wbmciLCJpYXQiOjE3NDAxODg5MDgsImV4cCI6MTc3MTcyNDkwOH0.SVWEQ-rLVEQhnju_SzEuBwEdbd9XYC9LAyz2sKaRLFU"
          alt="Disney Cover"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">Disney Official Store</h1>
            <p className="text-lg text-gray-200 max-w-2xl">
              Experience the magic with exclusive Disney products, subscriptions, and theme park packages
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Category Navigation */}
        <div className="flex items-center gap-4 mb-12 overflow-x-auto pb-4">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors ${
                activeCategory === category
                  ? 'bg-[#F8CA7E] text-white'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {filteredProducts.map(product => (
            <div 
              key={product.id}
              className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all group cursor-pointer"
              onClick={() => handleProductClick(product)}
            >
              <div className="aspect-[16/9] relative overflow-hidden">
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <Play className="w-12 h-12 text-white" />
                </div>
                <div className="absolute top-4 right-4 flex items-center gap-1 bg-black/60 backdrop-blur-sm px-3 py-1 rounded-full">
                  <Star className="w-4 h-4 text-yellow-400 fill-current" />
                  <span className="text-white text-sm">{product.rating}</span>
                </div>
              </div>

              <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                    {product.title}
                  </h3>
                  <span className="px-3 py-1 bg-[#F8CA7E]/10 text-[#F8CA7E] rounded-full text-sm">
                    {product.category}
                  </span>
                </div>

                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  {product.description}
                </p>

                <div className="flex items-center justify-between">
                  <span className="text-2xl font-bold text-gray-900 dark:text-white">
                    ${product.price}
                  </span>
                  <div className="flex items-center gap-3">
                    <button 
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Heart className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                    </button>
                    <button 
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Share2 className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                    </button>
                    <button 
                      className="flex items-center gap-2 px-6 py-2 bg-[#F8CA7E] text-white rounded-full hover:bg-[#e5b86b] transition-colors"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ShoppingBag className="w-5 h-5" />
                      <span>Buy Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}