export const concertVideos = [
  {
    id: 'concert1',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/concerts/Drake%20concert.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvY29uY2VydHMvRHJha2UgY29uY2VydC5tcDQiLCJpYXQiOjE3MzY4ODkzNTcsImV4cCI6MTc2ODQyNTM1N30.vYbvomqWpZ3cumkbviaWlhcdbhxVWrOvcP2zruXuhOE',
    title: 'Drake Concert Tickets',
    price: 199.00,
    celebrity: {
      name: 'Drake',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Drake.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvRHJha2UuanBnIiwiaWF0IjoxNzM5NDg0OTg1LCJleHAiOjE3NzEwMjA5ODV9.C4CpYr_2tuyksU9rScouuOuB_6S9vKKg_lKBmh9Aug4',
      verified: true
    }
  },
  {
    id: 'concert2',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/concerts/Lil%20yachty%20Concert.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvY29uY2VydHMvTGlsIHlhY2h0eSBDb25jZXJ0Lm1wNCIsImlhdCI6MTczNjg4OTM4NSwiZXhwIjoxNzY4NDI1Mzg1fQ.xLNSSTupsDNp5OD53eUTYHRwu7dRIbvtNWXPM_tWP-M',
    title: 'Lil Yachty Concert Tickets',
    price: 199.00,
    celebrity: {
      name: 'Lil Yachty',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Lil%20yachty.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvTGlsIHlhY2h0eS5qcGciLCJpYXQiOjE3Mzk0ODUwMDQsImV4cCI6MTc3MTAyMTAwNH0.HqwGWmmfO9TqJIgk7cNvazubv8togcvsfoPSRVmk1O4',
      verified: true
    }
  },
  {
    id: 'concert3',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/concerts/Travis%20Scott%20concert.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvY29uY2VydHMvVHJhdmlzIFNjb3R0IGNvbmNlcnQubXA0IiwiaWF0IjoxNzM2ODg5Mzk0LCJleHAiOjE3Njg0MjUzOTR9.X2tyX1zu48a0eNAqKJdIBbwSCC4sjkEeT9zW3DECV_M',
    title: 'Travis Scott Concert Tickets',
    price: 199.00,
    celebrity: {
      name: 'Travis Scott',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Travis%20Scott.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvVHJhdmlzIFNjb3R0LmpwZyIsImlhdCI6MTczOTQ4NTA0MCwiZXhwIjoxNzcxMDIxMDQwfQ.cuPL9SD2a0dk8KUMBqSCXPotjqBUVaAYrYzbY-sPMEI',
      verified: true
    }
  }
];