import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VERIFIED_CELEBRITIES } from '../../utils/constants';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { Compass } from 'lucide-react';

interface SearchSuggestionsProps {
  onSelect: (celebrity: string) => void;
}

export function SearchSuggestions({ onSelect }: SearchSuggestionsProps) {
  const navigate = useNavigate();

  const handleCelebrityClick = (celebrity: string) => {
    onSelect(celebrity);
    const celebritySlug = celebrity.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '')
      .replace(/[^a-z0-9]/g, '');
    
    // Special handling for Disney
    if (celebrity.toLowerCase() === 'disney') {
      navigate('/celebrity/disney');
      return;
    }
    
    navigate(`/dashboard/celebrity/${celebritySlug}`);
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
        Popular Celebrity Stores
      </h2>
      <div className="flex flex-wrap justify-center gap-6">
        {VERIFIED_CELEBRITIES.slice(0, 6).map((celebrity) => (
          <button
            key={celebrity}
            onClick={() => handleCelebrityClick(celebrity)}
            className="group flex flex-col items-center"
          >
            <div className="w-16 h-16 rounded-full overflow-hidden mb-2 border-2 border-transparent group-hover:border-[#F8CA7E] transition-colors">
              <img
                src={CELEBRITY_IMAGES[celebrity]}
                alt={celebrity}
                className="w-full h-full object-cover"
              />
            </div>
            <span className="text-sm text-gray-700 dark:text-gray-300 group-hover:text-[#F8CA7E] transition-colors">
              {celebrity}
            </span>
          </button>
        ))}
      </div>
      <button
        onClick={() => navigate('/dashboard/explore')}
        className="mt-6 flex items-center gap-2 px-6 py-2 mx-auto bg-[#F8CA7E] text-black rounded-lg hover:bg-[#e5b86b] transition-colors"
      >
        <Compass className="w-5 h-5" />
        <span>Explore</span>
      </button>
    </div>
  );
}