export const movieVideos = [
  {
    id: 'disney-shrek-1',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Shrek%20scene.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvU2hyZWsgc2NlbmUubXA0IiwiaWF0IjoxNzM5NjY4MDYwLCJleHAiOjE3NzEyMDQwNjB9.jEXCeoQKTOcZQvnCtcJveOKi7toq5tDrLsQvfe0-ZLY',
    title: 'Shrek Limited Edition Collection',
    price: 149.99,
    celebrity: {
      name: 'Disney',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Disney.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvRGlzbmV5LmpmaWYiLCJpYXQiOjE3Mzk0ODQ5NjIsImV4cCI6MTc3MTAyMDk2Mn0.ade28tHgHo5l61HvkLMZI857AK3vEQFQYCvSrQoRMS4',
      verified: true
    }
  }
];