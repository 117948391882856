import React, { useState, useRef, useEffect } from 'react';
import { Heart, Bookmark, ShoppingBag, Play, Pause, Volume2, VolumeX, Maximize2, ChevronDown, CheckCircle2 } from 'lucide-react';
import { formatPrice } from '../../utils/formatters';
import { useNavigate } from 'react-router-dom';

interface VideoPostProps {
  videoUrl: string;
  title: string;
  price: number;
  productImage?: string;
  onBuyNow: () => void;
  isLive?: boolean;
  celebrity: {
    name: string;
    imageUrl: string;
    verified: boolean;
  };
}

// Global state for mute status across all videos
let globalMuted = true;

function VideoPost({ videoUrl, title, price, productImage, onBuyNow, isLive, celebrity }: VideoPostProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(globalMuted);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
  const videoRef = useRef<HTMLVideoElement>(null);
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const navigate = useNavigate();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play().then(() => setIsPlaying(true)).catch(console.error);
          } else {
            video.pause();
            setIsPlaying(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(video);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleMuteChange = (e: CustomEvent<{ muted: boolean }>) => {
      if (videoRef.current) {
        const newMutedState = e.detail.muted;
        setIsMuted(newMutedState);
        videoRef.current.muted = newMutedState;
      }
    };

    window.addEventListener('muteStateChange', handleMuteChange as EventListener);
    return () => {
      window.removeEventListener('muteStateChange', handleMuteChange as EventListener);
    };
  }, []);

  const togglePlay = () => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (!videoRef.current) return;
    
    const newMutedState = !isMuted;
    globalMuted = newMutedState;
    setIsMuted(newMutedState);
    videoRef.current.muted = newMutedState;

    window.dispatchEvent(
      new CustomEvent('muteStateChange', { 
        detail: { muted: newMutedState } 
      })
    );
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (!isFullscreen) {
      videoRef.current?.play().catch(console.error);
      setIsPlaying(true);
    }
  };

  const handleProductClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Navigate to Shrek collectibles page if the video is about Shrek
    if (title.toLowerCase().includes('shrek')) {
      navigate('/disney/shrek-collectibles');
    }
  };

  return (
    <div className="relative max-w-[432px] mx-auto"> {/* Reduced by 10% from 480px */}
      {/* Celebrity Header */}
      <div className="absolute top-0 left-0 right-0 z-10 p-4 bg-gradient-to-b from-black/60 to-transparent">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-white">
            <img
              src={celebrity.imageUrl}
              alt={celebrity.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="text-white font-medium">{celebrity.name}</span>
            {celebrity.verified && (
              <CheckCircle2 className="w-4 h-4 text-[#F8CA7E]" />
            )}
          </div>
        </div>
      </div>

      <div className={`relative ${isFullscreen ? 'fixed inset-0 z-50 bg-black' : 'aspect-[9/16]'}`}>
        <video
          ref={videoRef}
          className={`w-full h-full object-cover object-top ${isFullscreen ? 'object-contain' : ''}`}
          src={videoUrl}
          playsInline
          loop
          muted={isMuted}
        />
        
        {/* Info Bar - Horizontal layout */}
        <div className="absolute top-20 left-4 right-4 flex items-center gap-2">
          {/* Countdown Timer */}
          <button className="flex-1 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-full">
            <span className="text-red-500 font-medium">{formatTime(timeLeft)}</span>
          </button>

          {/* Price Tag */}
          {!isLive && (
            <button className="flex-1 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-full">
              <span className="text-white font-medium">{formatPrice(price)}</span>
            </button>
          )}
        </div>

        {/* Controls Overlay */}
        <div className="absolute inset-0 flex flex-col">
          {/* Top Controls */}
          <div className="p-4 flex flex-col items-end gap-2 mt-20">
            <button
              onClick={toggleFullscreen}
              className="p-2 bg-black/40 backdrop-blur-sm rounded-full hover:bg-black/60 transition-colors"
            >
              <Maximize2 className="w-5 h-5 text-white" />
            </button>
            {title.toLowerCase().includes('shrek') && (
              <button
                onClick={handleProductClick}
                className="p-2 bg-black/40 backdrop-blur-sm rounded-full hover:bg-black/60 transition-colors"
              >
                <ChevronDown className="w-5 h-5 text-white" />
              </button>
            )}
          </div>

          {/* Center Play Button */}
          <div className="flex-1 flex items-center justify-center">
            <button
              onClick={togglePlay}
              className="p-4 bg-black/40 backdrop-blur-sm rounded-full hover:bg-black/60 transition-colors opacity-0 hover:opacity-100"
            >
              {isPlaying ? (
                <Pause className="w-8 h-8 text-white" />
              ) : (
                <Play className="w-8 h-8 text-white" />
              )}
            </button>
          </div>

          {/* Bottom Controls */}
          <div className="p-4 bg-gradient-to-t from-black/80 to-transparent">
            <h3 className="text-white font-medium mb-6">{title}</h3>
            
            <div className="flex items-center justify-between gap-2">
              <button className="p-2 hover:bg-white/10 rounded-full">
                <Heart className="w-6 h-6 text-white" />
              </button>
              
              <button
                onClick={onBuyNow}
                className="flex items-center justify-center gap-2 px-4 py-2 bg-[#F8CA7E] text-black rounded-full hover:bg-[#e5b86b] flex-1"
              >
                <ShoppingBag className="w-4 h-4" />
                <span className="font-medium">{isLive ? 'Bid Now' : 'Buy Now'}</span>
              </button>

              <button className="p-2 hover:bg-white/10 rounded-full">
                <Bookmark className="w-6 h-6 text-white" />
              </button>

              <button
                onClick={toggleMute}
                className="p-2 bg-black/40 backdrop-blur-sm rounded-full hover:bg-black/60 transition-colors"
              >
                {isMuted ? (
                  <VolumeX className="w-5 h-5 text-white" />
                ) : (
                  <Volume2 className="w-5 h-5 text-white" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { VideoPost };