import React from 'react';
import { BackButton } from '../../components/navigation/BackButton';
import { ShoppingBag } from 'lucide-react';

const shrekCollectibles = [
  {
    id: 1,
    name: "Shrek Limited Edition Toy Collection",
    price: 149.99,
    description: "Exclusive collectible toy set featuring Shrek and friends, perfect for dedicated fans and collectors.",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Shrek%20Stuff/Shrek_%20Toy%20collectibles.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1NocmVrIFN0dWZmL1NocmVrXyBUb3kgY29sbGVjdGlibGVzLmpwZyIsImlhdCI6MTc0MDA5NzYzNCwiZXhwIjoxNzcxNjMzNjM0fQ.QzES4kmMN_XXQ63V9gm47QsRuxzRGOCPWU_dKLFSoTA"
  },
  {
    id: 2,
    name: "Shrek Limited Edition Crocs",
    price: 79.99,
    description: "Special edition Crocs featuring Shrek-themed designs and charms. A unique blend of comfort and style.",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Shrek%20Stuff/Shrek%20Crocs%20limitted%20edition.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1NocmVrIFN0dWZmL1NocmVrIENyb2NzIGxpbWl0dGVkIGVkaXRpb24ucG5nIiwiaWF0IjoxNzQwMDk3NTg1LCJleHAiOjE3NzE2MzM1ODV9.U9ital3EOCbjTGfcM9TAYgVZLYVdV_FlRL109pyxLm4"
  },
  {
    id: 3,
    name: "Shrek Collector's Cereal Bowl",
    price: 29.99,
    description: "Limited edition lime green cereal bowl featuring Shrek's iconic design. Perfect for breakfast time!",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Shrek%20Stuff/SHREK-Kellogg-Cereal-Bowl-Lime-Green-Ogre%20collectibles.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1NocmVrIFN0dWZmL1NIUkVLLUtlbGxvZ2ctQ2VyZWFsLUJvd2wtTGltZS1HcmVlbi1PZ3JlIGNvbGxlY3RpYmxlcy5qcGciLCJpYXQiOjE3NDAwOTc1OTgsImV4cCI6MTc3MTYzMzU5OH0.4se3ANiuSzFqFWPKG1tDfGZ60yxpqzokMk-XPuTrTkg"
  },
  {
    id: 4,
    name: "Shrek Latex Mask Collectible",
    price: 49.99,
    description: "High-quality latex mask featuring Shrek's iconic face. Perfect for cosplay and display.",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Disney/Shrek%20Stuff/Shrek-Latex-Mask-%20collectibles.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvRGlzbmV5L1NocmVrIFN0dWZmL1NocmVrLUxhdGV4LU1hc2stIGNvbGxlY3RpYmxlcy5qcGciLCJpYXQiOjE3NDAwOTc2MTksImV4cCI6MTc3MTYzMzYxOX0.SSr9oAy-OQrFdLJj_hAlAuPE7RRui4jm_bTWYhUM678"
  }
];

export function ShrekCollectibles() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <BackButton />
      <div className="max-w-7xl mx-auto mt-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Shrek Collectibles
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            Exclusive collection of limited edition Shrek merchandise
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {shrekCollectibles.map((item) => (
            <div 
              key={item.id}
              className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all"
            >
              <div className="aspect-square relative overflow-hidden">
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  {item.name}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {item.description}
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-2xl font-bold text-[#F8CA7E]">
                    ${item.price.toFixed(2)}
                  </span>
                  <button className="flex items-center gap-2 px-6 py-3 bg-[#F8CA7E] text-white rounded-full hover:bg-[#e5b86b] transition-colors">
                    <ShoppingBag className="w-5 h-5" />
                    <span>Add to Cart</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}