import React, { useState } from 'react';
import { VideoPost } from '../video/VideoPost';
import { trendingVideos } from '../../data/videos/trending';
import { musicVideos } from '../../data/videos/music';
import { memeVideos } from '../../data/videos/meme';
import { concertVideos } from '../../data/videos/concerts';
import { movieVideos } from '../../data/videos/movies';

const categories = [
  { id: 'moments', label: 'Moments' }
];

const videoProducts = {
  moments: [...trendingVideos, ...musicVideos, ...memeVideos, ...concertVideos, ...movieVideos]
};

export function RecommendedSection() {
  const [activeCategory] = useState('moments');

  const handleBuyNow = (productId: string) => {
    console.log('Buy now clicked for product:', productId);
  };

  const currentVideos = videoProducts[activeCategory as keyof typeof videoProducts] || [];

  return (
    <section className="py-4">
      {/* Horizontally scrollable categories */}
      <div className="relative mb-6">
        <div className="flex overflow-x-auto gap-3 pl-4 pb-4 scrollbar-hide snap-x snap-mandatory">
          {categories.map((category) => (
            <button
              key={category.id}
              className={`flex-shrink-0 snap-start px-4 py-2 rounded-full text-sm font-medium transition-colors bg-[#F8CA7E] text-black`}
            >
              {category.label}
            </button>
          ))}
        </div>
      </div>

      {/* Video content */}
      {currentVideos.length > 0 ? (
        <div className="snap-y snap-mandatory h-[calc(100vh-280px)] overflow-y-auto">
          {currentVideos.map((product) => (
            <div 
              key={product.id}
              className="snap-start snap-always h-full flex mb-4 last:mb-0 pl-4"
            >
              <div className="w-[360px]">
                <VideoPost
                  videoUrl={product.videoUrl}
                  title={product.title}
                  price={product.price}
                  productImage={product.productImage}
                  onBuyNow={() => handleBuyNow(product.id)}
                  isLive={product.isLive}
                  celebrity={product.celebrity}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-[calc(100vh-280px)] text-gray-500 dark:text-gray-400">
          No Stardom videos yet
        </div>
      )}
    </section>
  );
}