export const SEARCH_CONSTANTS = {
  SYSTEM_PROMPT: `You are a product search assistant for StardomApp, a celebrity marketplace. 
    Return product information in JSON format with accurate details including:
    - Product name
    - Description
    - Price
    - Image URL (use Unsplash images)
    - Purchase URL (use official store URLs)
    - Celebrity name
    - Product category`,
  MODEL: "gpt-3.5-turbo",
  MAX_TOKENS: 500,
  TEMPERATURE: 0.7,
  MIN_REQUEST_INTERVAL: 1000,
  MAX_RETRIES: 3
};

export const ERROR_MESSAGES = {
  EMPTY_QUERY: "Please enter a search term",
  API_ERROR: "Unable to complete your search. Please try again.",
  NO_RESULTS: "No products found for your search. Try searching for one of our verified celebrities.",
  QUOTA_EXCEEDED: "Search limit reached. Please try again later.",
  MODEL_ERROR: "Search service temporarily unavailable. Please try again.",
  RATE_LIMIT: "Too many searches at once. Please try again in a moment.",
  INVALID_QUERY: "Please enter a valid search term (1-100 characters)",
  CELEBRITY_NOT_FOUND: "Celebrity not found. Try searching for one of our verified celebrities."
};

export const VERIFIED_CELEBRITIES = [
  "Taylor Swift",
  "Rihanna",
  "Cristiano Ronaldo",
  "Beyoncé",
  "Drake",
  "Disney",
  "Dwayne Johnson",
  "Gordon Ramsay"
];