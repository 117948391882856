import { Product } from './product';

export interface CelebrityData {
  name: string;
  bio: string;
  recentProject: {
    title: string;
    imageUrl: string;
    description: string;
  };
  recentInstagramPost: {
    imageUrl: string;
    caption: string;
  };
  upcomingTour: {
    title: string;
    imageUrl: string;
    description: string;
  };
}

export interface Celebrity {
  id: string;
  name: string;
  username: string;
  imageUrl: string;
  coverImage?: string;
  coverVideo?: string;
  bio: string;
  verified: boolean;
  followers: number;
  following: number;
  productsCount: number;
  location?: string;
  socialLinks: {
    instagram?: string;
    twitter?: string;
    website?: string;
  };
  products: Product[];
}

export const CELEBRITY_IMAGES: Record<string, string> = {
  'Kevin Hart': 'https://upload.wikimedia.org/wikipedia/commons/3/32/Kevin_Hart_2019.jpg',
  'Cristiano Ronaldo': 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Cristiano_Ronaldo_2018.jpg',
  'Rihanna': 'https://upload.wikimedia.org/wikipedia/commons/c/c2/Rihanna_Fenty_2018.png',
  'Taylor Swift': 'https://upload.wikimedia.org/wikipedia/commons/b/b5/191125_Taylor_Swift_at_the_2019_American_Music_Awards_%28cropped%29.png',
  'Dwayne Johnson': 'https://upload.wikimedia.org/wikipedia/commons/1/1f/Dwayne_Johnson_2014_%28cropped%29.jpg',
  'Gordon Ramsay': 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Gordon_Ramsay.jpg',
  'Beyoncé': 'https://upload.wikimedia.org/wikipedia/commons/1/17/Beyonc%C3%A9_at_The_Lion_King_European_Premiere_2019.png',
  'Drake': 'https://upload.wikimedia.org/wikipedia/commons/2/28/Drake_July_2016.jpg',
  'Tom Brady': 'https://upload.wikimedia.org/wikipedia/commons/2/27/Tom_Brady_2017.jpg',
  'Tyler, The Creator': 'https://upload.wikimedia.org/wikipedia/commons/3/35/Tyler%2C_the_Creator_2012.jpg',
  'Disney': 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Disney.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvRGlzbmV5LmpmaWYiLCJpYXQiOjE3Mzk0ODQ5NjIsImV4cCI6MTc3MTAyMDk2Mn0.ade28tHgHo5l61HvkLMZI857AK3vEQFQYCvSrQoRMS4'
};