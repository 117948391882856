export const trendingVideos = [
  {
    id: '7',
    videoUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Stardom%20Auction%20videos/copy_9CE85F4A-5FAF-42A4-86AD-B83BF83B8783.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9TdGFyZG9tIEF1Y3Rpb24gdmlkZW9zL2NvcHlfOUNFODVGNEEtNUZBRi00MkE0LTg2QUQtQjgzQkY4M0I4NzgzLm1wNCIsImlhdCI6MTczMzc4MDQ3OCwiZXhwIjoxNzY1MzE2NDc4fQ.4KCayw30-dFB-u9b0LhCekydSf3ozhC9vSpra6MNswA',
    title: 'Live Auction',
    celebrity: {
      name: 'Kim Kardashian',
      imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/scroll%20and%20shop/Moments%20Tab/Kim%20Khardashain.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzY3JvbGwgYW5kIHNob3AvTW9tZW50cyBUYWIvS2ltIEtoYXJkYXNoYWluLndlYnAiLCJpYXQiOjE3Mzk0ODQ4MTcsImV4cCI6MTc3MTAyMDgxN30.CvMH1lKHh46xfMYgPiQh7V18yldMj8qXqHAmxBULHAk',
      verified: true
    },
    price: 249.99,
    isLive: true
  }
];