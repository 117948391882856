"use client"

import type React from "react"

import { useState } from "react"
import { Calendar, Music, Palette, Book, Users, Dumbbell, Shirt, Film, Search, MapPin, Code, Crown } from "lucide-react"

type Category = {
  id: string
  icon: React.ElementType | null
  label: string
}

type Event = {
  id: number
  title: string
  date: string
  venue: string
  price: string
  imageUrl: string
  organizer: string
  followers: string
  category: string
}

const events: Event[] = [
  {
    id: 1,
    title: "Drake's Exclusive Yacht Party",
    date: "Sat, Apr 15, 8:00 PM",
    venue: "Miami Beach Marina • Miami, FL",
    price: "By Invitation Only • $5,000",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Drake's%20Exclusive%20Yacht%20Party.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9EcmFrZSdzIEV4Y2x1c2l2ZSBZYWNodCBQYXJ0eS53ZWJwIiwiaWF0IjoxNzQxMzEyNzM3LCJleHAiOjE3NzI4NDg3Mzd9.8Mn_IwKUaziAGtWn6mSzZt0_6UKrxekFiYXGYZPtINU",
    organizer: "Drake",
    followers: "279.3k followers",
    category: "music",
  },
  {
    id: 2,
    title: "Stephen King: Midnight Tales",
    date: "Fri, Apr 21, 10:00 PM",
    venue: "The Historic Stanley Hotel • Estes Park, CO",
    price: "Private Event • $2,500",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Stephen%20King%20Midnight%20Tales.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9TdGVwaGVuIEtpbmcgTWlkbmlnaHQgVGFsZXMuanBnIiwiaWF0IjoxNzQxMzEyNzU5LCJleHAiOjE3NzI4NDg3NTl9.vtxguaHzZmcZK6DKQ-IFY_rHyZY5c2dOpLAj3oNnHMc",
    organizer: "Stephen King",
    followers: "152k followers",
    category: "literature",
  },
  {
    id: 3,
    title: "Blac Chyna's Exclusive Gala",
    date: "Sat, Apr 22, 9:00 PM",
    venue: "The Highlight Room • Los Angeles, CA",
    price: "Limited Access • $1,500",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Blac%20Chyna's%20Exclusive%20Gala.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9CbGFjIENoeW5hJ3MgRXhjbHVzaXZlIEdhbGEud2VicCIsImlhdCI6MTc0MTMxMjgwNSwiZXhwIjoxNzcyODQ4ODA1fQ.Afr8LIjnf334ZzmqGyfj3dvPsGvnK_8YMpfxzn0Zay8",
    organizer: "Blac Chyna",
    followers: "189k followers",
    category: "social-media",
  },
  {
    id: 4,
    title: "LeBron James Elite Training Camp",
    date: "Sun, Apr 23, 10:00 AM",
    venue: "Lakers Training Facility • El Segundo, CA",
    price: "Exclusive Access • $3,500",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/LeBron%20James%20Elite%20Training%20Camp.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9MZUJyb24gSmFtZXMgRWxpdGUgVHJhaW5pbmcgQ2FtcC5qcGciLCJpYXQiOjE3NDEzMTI4MTgsImV4cCI6MTc3Mjg0ODgxOH0.rk7Lf9Fdh_Wpm3YlpNBy_8yRaKV0csScBlYURavkntU",
    organizer: "LeBron James",
    followers: "251k followers",
    category: "sports",
  },
  {
    id: 5,
    title: "Leonardo DiCaprio's Environmental Gala",
    date: "Fri, Apr 28, 7:00 PM",
    venue: "The Beverly Hilton • Beverly Hills, CA",
    price: "Invitation Only • $10,000",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Leonardo%20DiCaprio's%20Environmental%20Gala.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9MZW9uYXJkbyBEaUNhcHJpbydzIEVudmlyb25tZW50YWwgR2FsYS5qcGciLCJpYXQiOjE3NDEzMTI3OTEsImV4cCI6MTc3Mjg0ODc5MX0.fsQystnQijXC9eezWxizfifVt9b3pLawh7H_YryM4VE",
    organizer: "Leonardo DiCaprio Foundation",
    followers: "175k followers",
    category: "film",
  },
  {
    id: 6,
    title: "Beyoncé: Renaissance Listening Party",
    date: "Sat, Apr 29, 8:00 PM",
    venue: "Private Estate • Malibu, CA",
    price: "VIP Only • $7,500",
    imageUrl: "https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=800",
    organizer: "Beyoncé",
    followers: "319k followers",
    category: "music",
  },
  {
    id: 7,
    title: "Kevin Hart: Comedy Masterclass",
    date: "Sun, Apr 30, 7:00 PM",
    venue: "The Comedy Store • Hollywood, CA",
    price: "Limited Tickets • $1,200",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Kevin%20Hart%20Comedy%20Masterclass.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9LZXZpbiBIYXJ0IENvbWVkeSBNYXN0ZXJjbGFzcy5qcGciLCJpYXQiOjE3NDEzMTI3NDksImV4cCI6MTc3Mjg0ODc0OX0.AW3pdcyGwzNL6MTKsukVWCHy-edl3UK-bWScatE63bg",
    organizer: "Kevin Hart",
    followers: "210k followers",
    category: "entertainment",
  },
  {
    id: 8,
    title: "Gordon Ramsay's Chef's Table Experience",
    date: "Mon, May 1, 7:00 PM",
    venue: "Hell's Kitchen • Las Vegas, NV",
    price: "Exclusive Seating • $4,500",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Gordon%20Ramsay's%20Chef's%20Table%20Experience.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9Hb3Jkb24gUmFtc2F5J3MgQ2hlZidzIFRhYmxlIEV4cGVyaWVuY2UuamZpZiIsImlhdCI6MTc0MTMxMjY1NywiZXhwIjoxNzcyODQ4NjU3fQ.GM5jp693ssoD3OQYelTbuIu1fwuksAA-YZzONCZbR5U",
    organizer: "Gordon Ramsay",
    followers: "132k followers",
    category: "culinary",
  },
  {
    id: 9,
    title: "Donatella Versace: Atelier Preview",
    date: "Wed, May 3, 6:00 PM",
    venue: "Versace Mansion • Miami, FL",
    price: "By Invitation • $8,000",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Donatella%20Versace%20Atelier%20Preview.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9Eb25hdGVsbGEgVmVyc2FjZSBBdGVsaWVyIFByZXZpZXcuanBnIiwiaWF0IjoxNzQxMzEyNzcxLCJleHAiOjE3NzI4NDg3NzF9.jg_oRRmf0u_mN8yGk-BjvAB51BRNKxmv4r-CCN7i_KI",
    organizer: "Donatella Versace",
    followers: "145k followers",
    category: "fashion",
  },
  {
    id: 10,
    title: "Elon Musk: Future of AI Symposium",
    date: "Fri, May 5, 5:00 PM",
    venue: "SpaceX Headquarters • Hawthorne, CA",
    price: "Private Access • $15,000",
    imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/Stardom%20Events/Elon%20Musk%20Future%20of%20AI%20Symposium.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdGFyZG9tIEV2ZW50cy9FbG9uIE11c2sgRnV0dXJlIG9mIEFJIFN5bXBvc2l1bS5qcGciLCJpYXQiOjE3NDEzMTI3ODEsImV4cCI6MTc3Mjg0ODc4MX0.6B_FXvZeZTG4lN1Aq_M75ntSDcvM_Q6SH4z2JIVY55Q",
    organizer: "Elon Musk",
    followers: "287k followers",
    category: "technology",
  },
]

const categories: Category[] = [
  { id: "all", icon: null, label: "All" },
  { id: "music", icon: Music, label: "Music" },
  { id: "literature", icon: Book, label: "Literature" },
  { id: "social-media", icon: Users, label: "Social Media" },
  { id: "sports", icon: Dumbbell, label: "Sports" },
  { id: "film", icon: Film, label: "Film" },
  { id: "entertainment", icon: Film, label: "Entertainment" },
  { id: "culinary", icon: Palette, label: "Culinary" },
  { id: "fashion", icon: Shirt, label: "Fashion" },
  { id: "technology", icon: Code, label: "Technology" },
]

interface EventBadgeProps {
  category: string
}

function EventBadge({ category }: EventBadgeProps) {
  const getBadgeColor = (category: string) => {
    const colors: Record<string, string> = {
      Music: "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200",
      Literature: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200",
      "Social Media": "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-200",
      Sports: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200",
      Film: "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-200",
      Entertainment: "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200",
      Culinary: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200",
      Fashion: "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200",
      Technology: "bg-cyan-100 text-cyan-800 dark:bg-cyan-900 dark:text-cyan-200",
    }

    return colors[category] || "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getBadgeColor(category)}`}
    >
      {category}
    </span>
  )
}

function ExclusiveBadge() {
  return (
    <div className="absolute top-3 right-3 bg-black/70 text-white px-3 py-1 rounded-full flex items-center gap-1.5 text-xs font-medium backdrop-blur-sm">
      <Crown className="w-3 h-3" />
      <span>Exclusive</span>
    </div>
  )
}

interface EmptyStateProps {
  category: string
}

function EmptyState({ category }: EmptyStateProps) {
  return (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-4 mb-4">
        <Calendar className="h-8 w-8 text-gray-400" />
      </div>
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">No events found</h3>
      <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
        There are currently no {category} events available. Please check back later or explore other categories.
      </p>
    </div>
  )
}

interface EventCountBadgeProps {
  count: number
}

function EventCountBadge({ count }: EventCountBadgeProps) {
  return (
    <span className="ml-1.5 inline-flex items-center justify-center rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600 dark:bg-gray-700 dark:text-gray-300">
      {count}
    </span>
  )
}

export function EventsPage() {
  const [activeCategory, setActiveCategory] = useState<string>("all")
  const filteredEvents = activeCategory === "all" ? events : events.filter((event) => event.category === activeCategory)

  const getCategoryDisplayName = (categoryId: string): string => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.label : categoryId.charAt(0).toUpperCase() + categoryId.slice(1)
  }

  const getCategoryCount = (categoryId: string): number => {
    return categoryId === "all" ? events.length : events.filter((event) => event.category === categoryId).length
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search for exclusive celebrity events"
              className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-[#F8CA7E] dark:bg-gray-700 dark:text-white"
            />
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="mb-8 overflow-x-auto">
          <div className="flex gap-2 min-w-max">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`px-4 py-2 rounded-full flex items-center gap-2 text-sm font-medium transition-colors ${
                  activeCategory === category.id
                    ? "bg-[#F8CA7E] text-white"
                    : "bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
              >
                {category.icon && <category.icon className="w-4 h-4" />}
                {category.label}
                <EventCountBadge count={getCategoryCount(category.id)} />
              </button>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
            {activeCategory === "all"
              ? "Exclusive Celebrity Events"
              : `${getCategoryDisplayName(activeCategory)} Events`}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {activeCategory === "all"
              ? "Discover once-in-a-lifetime private events with your favorite celebrities."
              : `Exclusive ${getCategoryDisplayName(activeCategory)} events hosted by top celebrities.`}
          </p>
        </div>

        {filteredEvents.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredEvents.map((event) => (
              <div
                key={event.id}
                className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all group cursor-pointer"
              >
                <div className="aspect-[16/9] relative overflow-hidden">
                  <img
                    src={event.imageUrl || "/placeholder.svg?height=300&width=500"}
                    alt={event.title}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <ExclusiveBadge />
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-start mb-2">
                    <p className="text-[#F8CA7E] text-sm font-medium">{event.date}</p>
                    <EventBadge category={getCategoryDisplayName(event.category)} />
                  </div>
                  <h3 className="font-bold text-gray-900 dark:text-white mb-1">{event.title}</h3>
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400 text-sm mb-3">
                    <MapPin className="w-4 h-4" />
                    <span>{event.venue}</span>
                  </div>
                  <p className="text-[#F8CA7E] font-medium mb-4">{event.price}</p>
                  <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                    <span className="font-medium">{event.organizer}</span>
                    <span>•</span>
                    <span>{event.followers}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <EmptyState category={getCategoryDisplayName(activeCategory)} />
        )}
      </div>
    </div>
  )
}